import "./App.css";
import logo from './_images/android-chrome-192x192.png'
function App() {
  return <div className="App">
    <div>Future site of OpsDeskPro.com</div>
  <img src={logo} alt='' />
    </div>;
}

export default App;
